import { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';

const ProloguePage = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className={`w-full h-full flex flex-col bg-[#000000]`}>
      <Header />
      <div className='relative flex flex-col w-full md:h-fit items-center'>
        <img src='assets/img/prologue/title.png' className='w-[80%] md:w-[542px] top-[210px] absolute block md:hidden md:h-[34px] md:mt-6 z-[50]' />
        <img src='assets/img/landing/title-line.png' className='w-[90%] md:w-[1141px] absolute top-[230px] md:hidden md:h-[12px] mt-1 md:mt-4 z-[50]' />
        <video className='w-full h-[350px] md:h-[120vh] object-cover md:-mt-[10vh]' autoPlay loop muted>
          <source src='assets/img/landing/video-frame.mp4' type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <div className='z-[500] flex flex-col absolute top-[290px] md:top-[64vh] justify-center items-center w-full md:w-[1584px] md:h-[156px]'>
          <img src='assets/img/prologue/title.png' className='w-[80%] md:w-[542px] hidden md:block md:h-[34px] md:mt-6 z-[50]' />
          <img src='assets/img/landing/title-line.png' className='w-[90%] md:w-[1141px] hidden md:block md:h-[12px] mt-1 md:mt-4 z-[50]' />
          <p className='hidden md:flex text-[19px] text-center mt-2 w-[784px]'>The Prologue of our game is in progress.
            <br />
            <br />
            In this first stage you will discover a small fraction of planet Nebulous Prime,
            encountering unique characters and creatures that may help or hinder you.
            Begin to unravel the mysteries and uncover the lore of the world…and yourself.
            <br />
            <br />
            Subscribe to get the latest updates!</p>
          <div style={{ backgroundImage: 'url(assets/img/landing/section-line.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className="md:hidden mt-14 z-[100] w-full h-[26px] flex flex-col items-center" />
          <img src='assets/img/landing/landing-bg-2.png' className='w-full absolute md:hidden bg-[#0000009f] h-[472px] mt-8 md:mt-[80px] opacity-60' />
          <div style={{ backgroundImage: 'url(assets/img/update/update-list.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className="md:hidden w-[90%] h-[500px] -mt-6 flex flex-col items-center">
            <p className='text-[14px] text-center mt-3 w-[90%] z-[200]'>The Prologue of our game is in progress.
              <br />
              <br />
              In this first stage you will discover a small fraction of planet Nebulous Prime,
              encountering unique characters and creatures that may help or hinder you.
              Begin to unravel the mysteries and uncover the lore of the world…and yourself.
              <br />
              <br />
              Subscribe to get the latest updates!</p>
            <a href='https://linkedin.com/company/iplaymore' target='_blank' rel='noopener noreferrer' className='mt-[200px] z-[1000] absolute cursor-pointer'><img src='assets/img/prologue/linkedin.png' className='md:hidden w-[36px] h-[36px] mt-2' /></a>
            <img src='assets/img/prologue/character1-mb.png' className='absolute left-[6%] bottom-0 md:hidden w-[247px] h-[249px]' />
            <img src='assets/img/prologue/character2-mb.png' className='absolute right-[2.2%] bottom-0 md:hidden w-[273px] h-[260px]' />
          </div>
        </div>
        <div style={{ backgroundImage: `${isMobile ? 'url(assets/img/landing/fourth-bottom.png)' : 'url(assets/img/landing/fourth-bottom.png)'}`, backgroundSize: 'cover', backgroundPosition: 'center' }} className="bottom-0 md:z-[500] mt-[245px] md:-mt-[40vh] z-[600] w-full h-[750px] md:h-[800px] justify-end flex flex-col items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ProloguePage;