import { useInView } from 'react-intersection-observer';

const Discover = () => {
    const { ref, inView } = useInView({ threshold: 0.1 });

    return (

        <div ref={ref} className={`flex w-full justify-between px-3 md:px-[100px] md:pb-[100px]`}>
            <div className={`md:w-[600px] flex flex-col transition-transform duration-[1000ms] ${inView ? 'translate-x-0' : 'translate-x-[-100%]'}`}>
                <img src='assets/img/landing/discover.png' className='w-[249px] md:w-[496px] md:h-[50px] mt-8 md:mt-4 z-[50]' />
                <p className='text-[15px] md:text-[24px]'>Find your way across our small planet of
                    “Nebulous Prime” alone, or with your friends!
                    <br />
                    <br />
                    A fresh, breathtaking open-world built with the
                    Unreal Engine, combining our organic,
                    high-fantasy, and photorealistic art direction.
                    <br />
                    <br />
                    Interact with mythical creatures offering quests
                    to help you discover rare collectibles and
                    secret locations, while your foes will close in
                    trying to stop your progression.
                    <br />
                    <br />
                    Will you be able to find every hidden secret
                    scattered around the open-world to become
                    the ultimate explorer?
                </p>
            </div>
        </div>
    )
}
export default Discover;