import Header from '../../components/header';
import Footer from '../../components/footer';
import LogItem from '../../components/update/log-item';

const AboutUS = () => {

  return (
    <div className={`w-full h-full flex flex-col bg-[#000000]`}>
      <Header />
      <img src='assets/img/about-us/bg.png' className='w-full opacity-80 absolute h-full' />
      <div className='relative flex flex-col w-full h-fit items-center'>
        <div style={{ backgroundImage: 'url(assets/img/about-us/logo-line.png)', backgroundSize: 'cover', backgroundPosition: 'top' }} className="relative z-[100] w-full md:w-[1874px] md:h-[14px] flex mt-[140px]">
          <img src='assets/img/about-us/logo.png' className='w-[201px] md:w-[486px] h-[83px] md:h-[200px] mx-auto -mt-[110px] md:-mt-[90px]' />
        </div>
        <div className='flex flex-col w-full mt-4 md:mt-12 mb-[300px] z-[200]'>
          <div className='flex w-full flex-col md:flex-row justify-between h-fit text-[14px] md:text-[20px] px-4 md:px-[68px]'>
            <div className='flex flex-col md:w-[487px]'>
              <img src='assets/img/about-us/who-we-are.png' className='w-[246px] md:h-[18px] mb-4' />
              <p>We are a revolutionary transmedia company
                contracting worldwide game developers.
                Each with a passion for making games,
                contributing to designing, creating and
                developing our video game.</p>
              <img src='assets/img/about-us/about-thunder.png' className='w-[350px] md:w-[412px] -ml-7 md:h-[78px] mt-4 -mb-2' />
              <p>Inspired by the best elements of Quake, Tribes,
                Planetside, Skyrim, Silk Road Online, Everquest,
                World of Warcraft, and the Unreal Tournament
                video game franchises, THUNDER© is our hybrid,
                <br />
                <br />
                open-world game where teams work together to
                control regional territories all while unleashing
                powerful devices and exotic scepters.
                <br />
                <br />
                THUNDER© will include multiple unique
                playable characters.  Each will be able to
                complete narrative-based solo/co-op quests
                that benefit your play experience.
                <br />
                <br />
                The fast-paced, easy to access action will have
                you coming back for more and wanting to
                unravel the many secrets hidden throughout
                the world by rewarding your success.
                <br />
                <br />
                Our sister project will be a five-season,
                binge-worthy, character-driven television show.
                Entitled THUNDER© Rocks, players and viewers
                of all ages will fall in love with this expansive
                world of intertwining stories and music created
                directly in the game world and Unreal Engine.
              </p>
            </div>
            <div className='flex flex-col md:w-[597px] text-left md:text-right item-start md:items-end mt-8 md:mt-0'>
              <img src='assets/img/about-us/history.png' className='w-[360px] md:w-[490px] md:h-[24px] mb-4' />
              <p className='md:w-[430px]'>In 2019, we developed our
                Unreal Engine, Bear Isle prototype.
                In 2020 we took another step forward
                with the creation of our Intrepid vertical slice,
                which showcases our outdoor arena gameplay. </p>
              <img src='assets/img/about-us/mission.png' className='w-[264px] md:w-[294px] -ml-6 md:ml-0 md:-mr-5 md:h-[78px] mt-4 -mb-4' />
              <p>Our ‘Play, For Profit’ product model is our custom design genre. It is revolutionary by marketing
                directly to gamers using our proprietary
                Influencer Marketing tool that grants winners prize codes across various social networks to redeem online at iPlayMore.com</p>
              <img src='assets/img/about-us/profit.png' className='w-[360px] md:w-[530px] md:h-[18px] mt-8' />
              <p className='mt-8 md:w-[460px]'>The ‘Play, For Profit’ product model is our
                custom design genre.  It is revolutionary
                by marketing directly to gamers using
                our proprietary “Influencer Marketing Tool”
                that grants winners Game Gear prize codes
                across various social networks to redeem in
                our online Marketplace at iPlayMore.com
                <br />
                <br />
                Once redeemed, items increase in rarity and
                value over time, and can be sold for a profit at
                any time.  When offline, free players will use and
                increase the value of items to the surprise of the
                owners who will log back in and discover their
                Game Gear has increased in value.
              </p>
            </div>
          </div>
          <div className='flex flex-col items-center px-4 md:px-0 -mb-[80px] md:mb-0'>
            <img src='assets/img/about-us/contact-us.png' className='w-[360px] md:w-[724px] md:h-[74px] mt-8' />
            <p className='text-[16px] md:text-[24px] text-center'>
              Send an email to get in touch with the development team at <a href="mailto:thunder@iplaymore.com"><span className='text-primary-hover hover:text-secondary-hover'> thunder@iplaymore.com</span></a>
              <br />
              <br />
              As a private company, we are willing to share this lucrative opportunity with sophisticated investors.
              <br />
              <br />
              Do you have experience creating video games and want to join our team?
              Find us on <a href='https://linkedin.com/company/iplaymore' target='_blank' rel='noopener noreferrer' className='z-[300] cursor-pointer ml-2'><img src='assets/img/prologue/linkedin.png' className='inline-block w-[24px] h-[24px] md:w-[63px] md:h-[63px]' /></a> <p className='hidden md:inline-block'></p>
            </p>
          </div>
        </div>
        <div style={{ backgroundImage: 'url(assets/img/landing/fourth-bottom.png)', backgroundSize: 'cover', backgroundPosition: 'top' }} className="bottom-0 z-[100] w-full h-[800px] -mt-[400px] md:-mt-[40vh] justify-end flex flex-col items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AboutUS;