import { useInView } from 'react-intersection-observer';
import Craft from '../../components/landing/craft';
import Footer from '../../components/footer';

const FourthSection = () => {
    const { ref, inView } = useInView({ threshold: 0.1 });

    return (
        <div className='flex flex-col w-full'>
            <div className={`hidden absolute top-[3050px] left-0 w-full h-[900px] md:flex flex-col items-center z-[200] bg-[#000000c6] transition-opacity duration-1000`} style={{ opacity: inView ? 0 : 1, }} />
            <div style={{
                backgroundImage: 'url(assets/img/landing/fourth-bg.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                transition: 'opacity 4s ease-in-out'
            }} className='hidden relative md:flex flex-col items-center justify-between w-full h-[974px]'>
                <div ref={ref} className='absolute top-[100px] h-[670px] w-full'></div>
                <div style={{ backgroundImage: 'url(assets/img/landing/section-line.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className="z-[100] w-full h-[26px] flex flex-col items-center">
                    <img src='assets/img/landing/forge-your-way.png' className='w-[342px] h-[42px] mt-4 z-[50]' />
                    <img src='assets/img/landing/title-line.png' className='w-[604px] h-[10px] mt-2 z-[50]' />
                </div>
                <Craft />
            </div>

            <div className='md:hidden relative flex flex-col items-center justify-between w-full h-fit'>
                <div style={{ backgroundImage: 'url(assets/img/landing/section-line.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className="z-[100] w-full h-[26px] flex flex-col items-center">
                    <img src='assets/img/landing/forge-your-way.png' className='w-[60%] h-[42px] mt-4 z-[50]' />
                    <img src='assets/img/landing/title-line.png' className='w-[90%] h-[10px] mt-1 z-[50]' />
                </div>
                <Craft />
                <img src='assets/img/landing/section-line-mb.png' className='w-full h-[14px] mt-4 z-[50]' />
                <div style={{
                    backgroundImage: 'url(assets/img/landing/fourth-bg.png)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    transition: 'opacity 4s ease-in-out'
                }} className='w-full h-fit -mt-3 z-[49] py-12'>
                    <div className='flex justify-center items-center w-full relative'>
                        <img src='assets/img/landing/craft-detect.png' className={`absolute w-[100px] -scale-y-100 bottom-0 right-12 z-[50]`} />
                        <img src='assets/img/landing/craft-detect.png' className={`absolute w-[100px] -scale-x-100 top-0 left-12 z-[50]`} />
                        <img src='assets/img/landing/craft-item.png' className={`w-[70%] z-[50]`} />
                    </div>
                </div>
            </div>

            <div style={{ backgroundImage: 'url(assets/img/landing/section-line.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className="z-[100] w-full h-[26px] flex flex-col items-center" />
            <div className='flex flex-col w-full items-center mb-12'>
                <img src='assets/img/landing/thrilling.png' className='w-[90%] md:w-[1536px] md:h-[43px] md:mt-12 z-[50]' />
                <img src='assets/img/landing/title-line.png' className='w-[90%] mt-1 md:w-[1241px] md:h-[12px] md:mt-4 z-[50]' />
                <img src='assets/img/landing/profit.png' className='w-[70%] mt-1 md:w-[1030px] md:h-[26px] md:mt-4 z-[50] mb-6' />
            </div>
            <div style={{ backgroundImage: 'url(assets/img/landing/fourth-bottom.png)', backgroundSize: 'cover', backgroundPosition: 'top' }} className="z-[100] w-full -mt-[280px] md:-mt-[200px] h-[800px] flex flex-col justify-end items-center">
                <Footer />
            </div>
        </div>
    )
}
export default FourthSection;