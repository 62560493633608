import InitPage from './pages/initialPage';
import LandingPage from './pages/landingPage';
import ProloguePage from './pages/prologuePage';
import { Route, Routes } from 'react-router-dom';
import UpdateLogPage from './pages/updateLogPage';
import AboutUS from './pages/aboutUs';

function App() {
  if (window.location.hostname === 'iplaymore.vercel.app') {
      window.location.href = 'https://iplaymore.com/';
  }

  return (
    <div className="flex">
      <InitPage />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/prologue" element={<ProloguePage />} />
        <Route path="/update-logs" element={<UpdateLogPage />} />
        <Route path="/iplaymore" element={<AboutUS />} />
      </Routes>
    </div>
  );
}

export default App;