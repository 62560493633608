import { useInView } from 'react-intersection-observer';

const IntroContent = () => {
    const { ref, inView } = useInView({ threshold: 0.1 });

    return (
        <div ref={ref} className={`mt-6 md:mt-0 flex flex-col md:flex-row w-full justify-between px-3 md:px-[100px] md:pb-[100px] overflow-x-hidden`}>
            <div className={`hidden w-full md:w-[670px] md:flex flex-col transition-transform duration-[1000ms] ${inView ? 'translate-x-0' : '-translate-x-full'}`}>
                <img src='assets/img/landing/brood.png' className='w-[496px] h-[32px] mt-4 z-[50]' />
                <p className='text-[16px] md:text-[23px]'>Fierce beings whose goal is to subdue and enslave this world.
                    Vengeance warriors who experiment on the intelligent life forms
                    found here, leveraging them to become stronger, and wealthier
                    in an attempt to win every fight. The Brood show no mercy.</p>
            </div>
            <div className={`w-full md:w-[670px] flex flex-col items-end transition-transform duration-[1000ms] ${inView ? 'translate-x-0' : 'translate-x-full'}`}>
                <img src='assets/img/landing/kin.png' className='w-[488px] h-[32px] mt-4 z-[50]' />
                <p className='text-[16px] md:text-[23px] text-right'>Victorious heroes guided by confident morale, duty and honor,
                    doing all they can to grant peace where the Brood have left only a wake
                    of destruction.  Join them to save the creatures you will discover here.</p>
            </div>
            <div className={`md:hidden w-full md:w-[670px] flex flex-col transition-transform duration-[1000ms] ${inView ? 'translate-x-0' : '-translate-x-full'}`}>
                <img src='assets/img/landing/brood.png' className='w-[496px] h-[32px] mt-4 z-[50]' />
                <p className='text-[16px] md:text-[23px]'>Fierce beings whose goal is to subdue and enslave this world.
                    Vengeance warriors who experiment on the intelligent life forms
                    found here, leveraging them to become stronger, and wealthier
                    in an attempt to win every fight. The Brood show no mercy.</p>
            </div>
        </div>
    )
}
export default IntroContent;