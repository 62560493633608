import { Link } from "react-router-dom";
import { MenuItem } from "../header/menuItem";
import { useRef } from "react";

const Footer = () => {

  const inputRef = useRef<HTMLDivElement>(null);

  const handleSubscribe = () => {
    const inputText = inputRef.current?.textContent || "";
    fetch('YOUR_API_ENDPOINT', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: inputText }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div style={{ backgroundImage: 'url(assets/img/footer-bg.png)', backgroundSize: 'cover', backgroundPosition: 'top' }} className={`z-[500] flex flex-col w-full h-[540px] bg-cover items-center py-4 px-4 md:px-10 justify-between -mt-1`}>
      <div className='flex flex-col items-center pt-12'>
        <img src='assets/img/landing/sub-title.png' className='w-full mt-1 md:w-[920px] md:h-[68px] z-[50]' />
        <p className='text-[23px] text-center md:text-[21px] mb-4 h-[128px] md:h-fit'>Receive updates and calls to action as releases of our game become exclusively available online.</p>
        <div
          ref={inputRef}
          style={{
            backgroundImage: 'url(assets/img/landing/text-box.png)',
            backgroundSize: 'cover'
          }}
          contentEditable={true} suppressContentEditableWarning={true}
          className='leading-[48px] md:leading-[78px] focus:outline-none text-center text-[14px] md:text-[24px] hover:text-secondary-hover font-[400] flex items-center justify-center w-[350px] mt-1 md:w-[556px] h-[48px] md:h-[78px] md:mt-4 z-[50]'
        >
          {""}
        </div>
        <div
          style={{
            backgroundImage: 'url(assets/img/landing/sub-btn.png)',
            backgroundSize: 'cover'
          }}
          onClick={handleSubscribe}
          className='cursor-pointer text-[14px] md:text-[24px] hover:text-secondary-hover font-[400] flex items-center justify-center w-[130px] h-[57px] mt-1 md:w-[178px] md:h-[78px] md:mt-3 z-[50] animate-moveUpDown'
        >Subscribe
        </div>
      </div>
      <div className='flex flex-col w-full md:w-[400px] items-center justify-center'>
        <div className="flex items-center gap-3">
          <Link to="/iplaymore"><img src="assets/img/logo-sm.png" className="w-[110px] md:w-[122px] md:h-[44px]" /></Link>
          <Link to="/"><img src="assets/img/logo-left-sm.png" className="w-[200px] md:w-[226px] md:h-[22px]" /></Link>
        </div>
        <div className="flex items-center gap-4 text-[11px] mt-[2px]">
          <MenuItem to="/">THUNDER©</MenuItem>
          <MenuItem to="/prologue">Prologue</MenuItem>
          <MenuItem to="/update-logs">Update Logs</MenuItem>
          <MenuItem to="/iplaymore">iPlayMore</MenuItem>
        </div>
        <p className="text-[11px] mt-[2px]">
          © iPlayMore - ALL RIGHT RESERVED
        </p>
        <p className="text-[11px] mt-[2px]">
          Proprietary Copyright  Intellectual Property of IMU Studios
        </p>
      </div>
    </div>
  );
};

export default Footer;