const FirstSection = () => {

    return (
        <div style={{ backgroundImage: 'url(assets/img/landing/landing-bg.png)', backgroundSize: 'cover' }} className='flex flex-col items-center w-full h-[350px] md:h-[951px] relative'>
            <img src='assets/img/landing/landing-bg-2.png' className='w-full hidden md:absolute bg-[#0000009f] md:h-[960px]' />
            <div className="w-full hidden md:flex flex-col justify-center items-center mt-8">
                <img src='assets/img/landing/discover-to-your-hero.png' className='w-[80%] md:w-[1490px] h-[11px] md:h-12 z-[50]' />
                <img src='assets/img/landing/title-line.png' className='w-[90%] md:w-[904px] md:h-[15px] mt-2 md:mt-6 z-[50]' />
            </div>
            <div className="w-full absolute flex top-4 md:hidden flex-col justify-center items-center z-[100]">
                <img src='assets/img/landing/discover-to-your-hero.png' className='w-[80%] md:w-[1490px] h-[11px] md:h-12 z-[50]' />
                <img src='assets/img/landing/title-line.png' className='w-[90%] md:w-[904px] md:h-[15px] mt-2 md:mt-6 z-[50]' />
            </div>
            <div className='w-full h-full md:w-[1357px] md:h-[766px] md:mt-6 z-[50] relative'>
                <video className='w-full h-full object-cover' autoPlay loop muted>
                    <source src='assets/img/landing/video-frame.mp4' type='video/mp4' />
                    Your browser does not support the video tag.
                </video>
                <div
                    style={{
                        backgroundImage: 'url(assets/img/landing/button-bg.png)',
                        backgroundSize: 'cover'
                    }}
                    className='cursor-pointer text-[14px] md:text-[24px] hover:text-secondary-hover font-[400] flex items-center justify-center w-[292px] md:w-[556px] h-[41px] md:h-[78px] z-[50] absolute top-2/3 left-[calc(50%-146px)] md:left-[400px] animate-moveUpDown'
                ><a href="https://www.youtube.com/watch?v=R6tAwjXAU6Y" target='_blank' rel='noopener noreferrer' className=" no-underline">
                        Click here to view our teaser!
                    </a>
                </div>
            </div>
        </div>
    )
}
export default FirstSection;
