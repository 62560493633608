import { Link } from "react-router-dom";

type MenuItemProps = {
    to: string;
    mobile?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
};

export const MenuItem = ({ children, to, mobile = false, onClick }: MenuItemProps) => (
    <>
        {
            mobile ?
                <Link onClick={onClick} className="mb-1 flex justify-center items-center w-full h-[36px] bg-[#090909] border border-[#666666]" to={to}>
                    {children}
                </Link>
                :
                <Link to={to} className="hover:text-primary-hover cursor-pointer">
                    {children}
                </Link>
        }
    </>
);