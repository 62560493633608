import { useInView } from 'react-intersection-observer';

const Craft = () => {
    const { ref, inView } = useInView({ threshold: 0.1 });

    return (
        <>
            <div className='hidden md:flex absolute w-[42vw] left-[120px] top-[280px]'>
                <img src='assets/img/landing/craft-detect.png' className={`absolute w-[232px] -scale-y-100 bottom-0 right-0 h-[80px] z-[50] transition-transform duration-[1000ms] ${inView ? 'translate-y-0' : 'translate-y-[100%]'}`} />
                <img src='assets/img/landing/craft-detect.png' className={`absolute w-[232px] -scale-x-100 top-0 left-0 h-[80px] z-[50] transition-transform duration-[1000ms] ${inView ? 'translate-y-0' : 'translate-y-[-100%]'}`} />
                <img src='assets/img/landing/craft-item.png' className={`w-full h-full z-[50] transition-transform duration-[1000ms] ${inView ? 'opacity-100' : 'opacity-0'}`} />
            </div>
            <div ref={ref} className={`z-[100] flex w-full justify-end px-2 md:px-[100px] md:pb-[240px] overflow-x-hidden`}>
                <div className={`w-full items-end flex flex-col transition-transform duration-[1000ms] ${inView ? 'translate-x-0' : 'translate-x-full'}`}>
                    <img src='assets/img/landing/craft.png' className='w-[85%] md:w-[752px] md:h-[66px] mt-10 md:mt-4 z-[50]' />
                    <p className='w-[90%] md:w-[590px] text-[15px] md:text-[24px] text-right mt-2 md:mt-0'>
                        Enhance your Game Gear and devices
                        to release their greater ascending potential
                        to become more powerful!  During your journey,
                        you’ll be able to discover recipes and develop incredible
                        devices with different features and playstyles, making
                        your experience exclusive, personal and incredible!
                        <br />
                        <br />
                        Every device is unique in their own right:
                        Some are entirely mechanical, others are hybrids
                        of mechanical and living parts, and others purely
                        organic, constructed from living beings!
                    </p>
                    <img src='assets/img/landing/craft-bottom.png' className='hidden md:flex w-[752px] h-[44px] mt-4 z-[50]' />
                </div>
            </div>
        </>
    )
}
export default Craft;